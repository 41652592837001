import React, { useState } from 'react'
import swal from 'sweetalert'
import { useForm, Controller } from 'react-hook-form'

import { postContactForm } from '../../../api/api'
import InputMaskCustom from '../../../global/utils'

export default function Form() {

  const CLIENT_ID = 2 //Oliva Tech
  const maxLengthDefault = { value: 200, message: "Limite máximo de 200 caracteres." }
  const { setError, register, formState: { errors }, reset, handleSubmit, control, clearErrors } = useForm();
  const [sendingForm, setSendingForm] = useState(false);

  const onSubmit = data => {
    setSendingForm(true)
    data["client_id"] = CLIENT_ID
    setTimeout(() => {
      postContactForm(data)
        .then(() => {
          setSendingForm(false)
          swal("Tudo certo!", "Seu contato foi recebido com sucesso.", "success")
          reset()
        })
        .catch((error) => {
          Object.keys(error.response.data.errors).map((item) => {
            setError(item.toLowerCase(), { type: "server", message: error.response.data.errors[item][0] }, { shouldFocus: true })
          })
        })
        .finally(() => {
          setSendingForm(false)
        });

    }, 2000);
  };

  return (
    <>
      <div className="portfolio-contact amoeba">
        <div className="portfolio-contact__container">
          <h2 className="portfolio-contact__title">Vamos Criar Algo Incrível</h2>
          <div className="portfolio-contact__form">
            <div className="contact__error"></div>
            <form className="simple_form new_contact" noValidate="noValidate" acceptCharset="UTF-8" onSubmit={handleSubmit(onSubmit)} >
              <div className="simple_form__row">
                <div className="input string required contact_name">
                  <label className="string required" htmlFor="contact_name"> Nome</label>
                  <input className="string required" type="text" id="contact_name" placeholder="Como podemos te chamar?"
                    {...register("name",
                      {
                        required: "Campo obrigatório",
                        maxLength: maxLengthDefault
                      })
                    }
                  />
                  {errors.name && <p>{errors.name?.message}</p>}
                </div>
              </div>
              <div className="simple_form__group">
                <div className="simple_form__row">
                  <div className="input email required contact_email">
                    <label className="email required" htmlFor="contact_email"> Email</label>
                    <input className="string email required" type="email" id="contact_email" placeholder="Digite seu e-mail"
                      {...register("email",
                        {
                          required: "Campo obrigatório",
                          maxLength: maxLengthDefault
                        })
                      }
                    />
                    {errors.email && <p>{errors.email?.message}</p>}
                  </div>
                </div>
                <div className="simple_form__row">
                  <div className="input tel required contact_phone">
                    <label className="tel required" htmlFor="contact_phone"> Celular</label>
                    <Controller
                      control={control}
                      {...register("phone",
                        {
                          required: "Campo obrigatório",
                          maxLength: maxLengthDefault
                        })
                      }
                      render={({ field: { onChange, onBlur, ref } }) => (
                        <InputMaskCustom
                          mask="(99) 99999-9999"
                          onBlur={onBlur}
                          onChange={onChange}
                          inputRef={ref}

                        />

                      )}
                    />
                    {errors.phone && <p>{errors.phone?.message}</p>}
                  </div>
                </div>
              </div>
              <div className="simple_form__row">
                <div className="input text required contact_description">
                  <label className="text required" htmlFor="contact_description"> Deixe uma mensagem se quiser :)</label>
                  <textarea
                    className="text required"
                    placeholder="Qual é o seu projeto? Quer fazer um orçamento? Fale com a gente!"
                    id="contact_description"
                    {...register("message",
                      {
                        required: "Campo obrigatório",
                        maxLength: maxLengthDefault
                      })
                    }
                  >
                  </textarea>
                  {errors.message && <p>{errors.message?.message}</p>}
                </div>
              </div>
              <div className="simple_form__row simple_form__row--submit">
                {!sendingForm && <input type="submit" value="Enviar" className="btn btn--primary"  />}
                {sendingForm && <p style={{fontSize: "17px", fontWeight: "700"}}>Enviando...</p>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
