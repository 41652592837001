import React, { useState } from 'react'
import swal from 'sweetalert'
import { useForm, Controller } from 'react-hook-form'

import { postContactForm } from '../../../api/api'
import InputMaskCustom from '../../../global/utils'


export default function Form() {

  const CLIENT_ID = 2 //Oliva Tech
  const maxLengthDefault = { value: 200, message: "Limite máximo de 200 caracteres." }
  const { setError, register, formState: { errors }, reset, handleSubmit, control, clearErrors } = useForm();
  const [sendingForm, setSendingForm] = useState(false);

  const onSubmit = data => {
    setSendingForm(true)
    data["client_id"] = CLIENT_ID
    setTimeout(() => {
      postContactForm(data)
        .then(() => {
          setSendingForm(false)
          swal("Tudo certo!", "Seu contato foi recebido com sucesso.", "success")
          reset()
        })
        .catch((error) => {
          Object.keys(error.response.data.errors).map((item) => {
            setError(item.toLowerCase(), { type: "server", message: error.response.data.errors[item][0] }, { shouldFocus: true })
          })
        })
        .finally(() => {
          setSendingForm(false)
        });

    }, 2000);
  };

  return (
    <>
      <div className="cd-section amoeba" id="section7">
        <section className="contact">
          <div className="contact__yellow-shape">
            <img src={process.env.PUBLIC_URL + "/assets/images/contact-yellow-shape.svg"} />
          </div>
          <div className="contact__form">
            <div className="contact__error"></div>
            <form className="simple_form new_contact" id="new_contact" noValidate="noValidate" acceptCharset="UTF-8" onSubmit={handleSubmit(onSubmit)}>
              <div className="simple_form__row">
                <div className="input string required contact_name">
                  <label className="string required" htmlFor="contact_name"> Nome</label>
                  <input className="string required" type="text" id="contact_name" placeholder="Como podemos te chamar?"
                    {...register("name",
                      {
                        required: "Campo obrigatório",
                        maxLength: maxLengthDefault
                      })
                    }
                  />
                  {errors.name && <p>{errors.name?.message}</p>}
                </div>
              </div>
              <div className="simple_form__group">
                <div className="simple_form__row">
                  <div className="input email required contact_email">
                    <label className="email required" htmlFor="contact_email"> Email</label>
                    <input className="string email required" type="email" id="contact_email" placeholder="Digite seu e-mail"
                      {...register("email",
                      {
                        required: "Campo obrigatório",
                        maxLength: maxLengthDefault
                      })
                    }
                    />
                    {errors.email && <p>{errors.email?.message}</p>}
                  </div>
                </div>
                <div className="simple_form__row">
                  <div className="input tel required contact_phone">
                    <label className="tel required" htmlFor="contact_phone"> Celular</label>
                    <Controller
                      control={control}
                      {...register("phone",
                        {
                          required: "Campo obrigatório",
                          maxLength: maxLengthDefault
                        })
                      }
                      render={({ field: { onChange, onBlur, ref } }) => (
                        <InputMaskCustom
                          mask="(99) 99999-9999"
                          onBlur={onBlur}
                          onChange={onChange}
                          inputRef={ref}

                        />

                      )}
                    />
                    {errors.phone && <p>{errors.phone?.message}</p>}

                  </div>
                </div>
              </div>
              <div className="simple_form__row">
                <div className="input text required contact_description">
                  <label className="text required" htmlFor="contact_description">Deixe uma mensagem :)</label>
                  <textarea
                    className="text required"
                    placeholder="Qual é o seu projeto? Quer fazer um orçamento? Fale com a gente!"
                    id="contact_description"

                    {...register("message",
                      {
                        required: "Campo obrigatório",
                        maxLength: maxLengthDefault
                      })
                    }
                  >
                  </textarea>
                  {errors.message && <p>{errors.message?.message}</p>}
                </div>
              </div>
              <div className="simple_form__row">
                <h2 className="contact__create__title contact__create__title--desktop">VAMOS CRIAR ALGO <br />INCRÍVEL!</h2>
                <div className="contact__form__call">
                  {!sendingForm && <input type="submit" value="Enviar" className="btn btn--primary" />}
                  {sendingForm && <p>Enviando...</p>}
                </div>
              </div>
            </form>
          </div>
          <div className="contact__header">
            <div className="contact__title">#Agoravai</div>
            <h3 className="contact__subtitle">
              <b>Se você tem uma grande ideia, e tá precisando de uma ajudinha.</b>
              Se ela precisa de uma estrutura tecnologica por trás, com um time especializado no que faz,
              nós podemos ajudar.<br /><br /> Preencha o formulário e entraremos em contato o mais breve possível.
            </h3>
            <div className="contact__create">
              <h2 className="contact__create__title">VAMOS CRIAR ALGO <br />INCRÍVEL!</h2>
            </div>
          </div>
          <div className="contact__connect">
            <h3 className="contact__connect__title">
              Mas se preferir, chama lá em nossas <a href="https://www.instagram.com/olivatech.dev/" target="_blank"><b>redes sociais </b></a>
              ou envie um email para <a href='mailto:contato@olivatech.dev'><b>contato@olivatech.dev</b>
              </a>
            </h3>
          </div>
        </section>
      </div>
    </>
  )
}
