import React from 'react'

export default function Solution({data}) {
    return (
        <>
            <div className="portfolio-solution amoeba">
                <div className="portfolio-solution__container">
                    <div className="portfolio-solution__title">
                        <h2>A SOLUÇÃO</h2>
                    </div>
                    <div className="portfolio-solution__asset"></div>
                    <div className="portfolio-solution__description">
                        <p dangerouslySetInnerHTML={{ __html: data?.description }} />
                    </div>
                    <div className="portfolio-solution__show">
                        <img src={process.env.PUBLIC_URL + data?.image_site_all } />
                    </div>
                    <div className="portfolio-solution__highlight">
                        <div className="portfolio-solution__highlight__info">
                            <h2 className="portfolio-solution__highlight__title">
                                {data?.yellow_title}
                            </h2>
                            <p className="portfolio-solution__highlight__description">
                            {data?.yellow_text}
                            </p>
                        </div>
                        <div className="portfolio-solution__highlight__image">
                            <img src={process.env.PUBLIC_URL + data?.image_notebook} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
