import React from 'react'

function Header() {
    return (
        <>
            <div className="vertical-navigation" id="cd-vertical-nav">
                <div className="vertical-navigation__list">
                    <a data-number="1" href="#section1">
                        <div className="vertical-navigation__list-item">
                            <div className="vertical-navigation__list-item__dot">•</div>
                            <div className="vertical-navigation__list-item__label">home</div>
                        </div>
                    </a>
                    <a data-number="2" href="#section2">
                        <div className="vertical-navigation__list-item">
                            <div className="vertical-navigation__list-item__dot">•</div>
                            <div className="vertical-navigation__list-item__label">sobre nós</div>
                        </div>
                    </a>
                    <a data-number="3" href="#section3">
                        <div className="vertical-navigation__list-item">
                            <div className="vertical-navigation__list-item__dot">•</div>
                            <div className="vertical-navigation__list-item__label">pacotes</div>
                        </div>
                    </a>
                    <a data-number="4" href="#section4">
                        <div className="vertical-navigation__list-item">
                            <div className="vertical-navigation__list-item__dot">•</div>
                            <div className="vertical-navigation__list-item__label">portfólio</div>
                        </div>
                    </a>
                    <a data-number="5" href="#section5">
                        <div className="vertical-navigation__list-item">
                            <div className="vertical-navigation__list-item__dot">•</div>
                            <div className="vertical-navigation__list-item__label">nossos parceiros</div>
                        </div>
                    </a>
                    <a data-number="6" href="#section6">
                        <div className="vertical-navigation__list-item">
                            <div className="vertical-navigation__list-item__dot">•</div>
                            <div className="vertical-navigation__list-item__label">blog</div>
                        </div>
                    </a>
                    <a data-number="7" href="#section7">
                        <div className="vertical-navigation__list-item">
                            <div className="vertical-navigation__list-item__dot">•</div>
                            <div className="vertical-navigation__list-item__label">contato</div>
                        </div>
                    </a>
                    <a data-number="8" href="#section8"></a>
                </div>
            </div>
        </>
    )
}

export default Header