import React from 'react'

export default function Portfolio({cases}) {
    return (
        <>
            <div className="cd-section amoeba" id="section4">
                <section className="project">
                    <div className="project__container">
                        <h2 className="project__header">Conheça nossos principais cases</h2>
                        <div className="project__card-list">
                            {
                                cases.map((item, index) => {
                                    return (
                                        <a className="project__card" href={`/cases/${item.slug}`} key={index}>
                                            <div className={`project__card__logo project__card__logo--${item.keycss}`} ></div>
                                            <p className="project__card__more"> ver mais</p>
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
