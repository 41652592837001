import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from '../src/pages/Home'
import Case from '../src/pages/Case'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/cases/:slug" element={<Case />} />
        <Route exact path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
