export const cases = [
    {
        name: "Janderson Vidros",
        slug: "janderson-vidros",
        keycss: "janderson",
        header: {
            name: "Janderson Vidros",
            tags: ["UI/UX DESIGN", "LANDING PAGE", "CONVERSÃO E VENDA"],
            description: `<b>Janderson Vidros </b>é uma empresa de prestação de serviços e montagem de vidros em geral. 
                Ela cuida de todo o processo, desde a medição, compra e montagem dos vidros nos imóveis.
                Nosso desafio com o cliente era aumentar sua presença digital e captar leads para fechar novos serviços.`,

        },
        banner: {
            subtitle: "Topo Página Inicial Janderson Vidros",
            image: "/assets/cases/jandersonvidros/banner.png"
        },
        problem: {
            description: `
            Como funciona o processo de montagem? Os valores dos vidros são os mesmos sempre?
            Que tipo de vidro utilizar em meu imóvel? Posso ver seus trabalhos anteriores? Como contratar?
            <br/><br/>
            Essas são algumas perguntas que a empresa recebia a todo momento.
            Mesmo com tantas dúvidas, o Janderson conseguia lidar com cada cliente individualmente respondendo os questionamentos.
            Porém perdia muito tempo e clientes as vezes não fechavam o negóci.
            Algumas das informações são técnicas demais e nem sempre o cliente final conseguia entender.
            `,
            image_mobile: "/assets/cases/jandersonvidros/problem_mobile.png",
            objectives: ["Proporcionar uma jornada do usuário;", "Destacar os trabalhos já feitos;", "Coletar leads pelo formulário e whatsapp;", "Transmitir modernidade e presença digital."]
        },
        solution: {
            description: `
            Construimos um site totalmente moderno e fácil de entender, apresentando as principais informações
            da empresa conforme o usuário desce a página. Apresentando logo ao centro do site o portifolio do cliente
            com muitas imagens e exemplos de serviços prestados, desta forma o usuário também consegue visualizar os últimos
            depoimentos dos serviços realizados e tirar suas principais dúvidas de maneira rápida e fácil através do FAQ.
            Utilizou-se o tempo todos CTAs em formato de botões para direcionar o usuário ao formulário de contato para
            captar o lead.`,
            image_site_all: "/assets/cases/jandersonvidros/site_all.png",
            yellow_title: "Foco em conversão e vendas.",
            yellow_text: `Tudo foi construído e pensado para que a jornada do usuário no site terminasse no preenchimento do formulário de contato.`,
            image_notebook: "/assets/cases/jandersonvidros/notebook_photo.png"
        },
        result:{
            description: `Em algumas semanas toda identidade e landing page foram construídas do absoluto zero e atualmente
            a empresa Janderson Vidros aumentou sua presença digital utilizando também seu instagram em conjunto.
            Agora é um dos principais prestadores de serviço na cidade de Volta Redonda - RJ e região e em constante expansão.`,
            link: "https://jandersonvidros.com",
            image_tablet: "/assets/cases/jandersonvidros/tablet_moldure.png"
        },
        otherCases: [{
            name: "Skin Matcher",
            slug: "skin-matcher",
            logo: "/assets/cases/skinmatcher.png",
        }]
    },
    {
        name: "Skin Matcher",
        slug: "skin-matcher",
        keycss: "skinmatcher",
        header: {
            name: "Skin Matcher",
            tags: ["UI/UX DESIGN", "WEB SCRAPING", "INTELIGÊNCIA ARTIFICIAL"],
            description: `<b>Skin Matcher</b> é uma startup que surgiu com o propósito de ajudar a galera gamer. 
                Focada no cenário do jogo Counter-Strike Global Offensive e utilizando processamento de linguagem natural focou em comparação de preços de skins do jogo.
                Nosso desafio apoiando essa startup foi desenvolver a aplição e conseguir captar e comparar os preços em diversas lojas virtuais do mercado.`,

        },
        banner: {
            subtitle: "Página Inicial do Skin Matcher",
            image: "/assets/cases/skinmatcher/banner.png"
        },
        problem: {
            description: `
            O que é uma SKIN? Como conseguir os valores das skins nas lojas?
            Como tornar a busca rápida e ao mesmo tempo com boa usabilidade?
            <br/><br/>
            Essas foram algumas perguntas que a startup discutia a todo momento.
            Utilizando então técnicas de PNL e Inteligência Artificial, nós conseguimos contribuir construindo um sistema
            otimizado com web scraping e cache de busca.
            Além disso, queriamos também atingir o público da startup com um visual bem descontraído e com uso de cores fortes e expressivas.
            `,
            image_mobile: "/assets/cases/skinmatcher/mobile.png",
            objectives: ["Proporcionar uma usabilidade;", "Exibir os preços das skins (produtos);", "Coletar e comparar o máximo de preços possível;", "Transmitir modernidade."]
        },
        solution: {
            description: `
            Foi constrúido um site utilizando o ReactJS devido a necessidade de interação o tempo todo e com rápidas respostas. <br/>
            A jornada do usuário começa com ele escolhendo alguma das skins no TOP 5 das mais procuradas ou então buscando uma skin especifica.
            Navegando entre as etapas, que se deu através de uma roleta que já é caracteristica no próprio game, o usuário consegue buscar fácilmente
            sua skin desejada e com poucos cliques saber qual os menores valores nas lojas inclusive na loja oficial do jogo (Steam).
            No fim, o usuário consegue clicando no link ir diretamente à loja e no menor preço. <br/> Simples, rápido e direto.
            `,
            image_site_all: "/assets/cases/skinmatcher/armas.png",
            yellow_title: "Foco em comparar preços.",
            yellow_text: `Tudo foi construído e pensado para entregar o resultado o mais rápido e eficiente.`,
            image_notebook: "/assets/cases/skinmatcher/notebook.png"
        },
        result:{
            description: `
            Em algumas semanas toda identidade e sistema de webscraping e busca.
            A startup atingiu seu objetivo de comparar os preços e exibir a tabela dos menores preços das lojas.
            Atualmente buscam financiamento e parcerias com as lojas de skins para continuar o projeto e trazer essa facilidade para os players de CS:GO.
            `,
            link: "",
            image_tablet: "/assets/cases/skinmatcher/tablet.png"
        },
        otherCases: [{
            name: "Janderson Vidros",
            slug: "janderson-vidros",
            logo: "/assets/cases/janderson-vidros-white.png",
        }]
    }
]