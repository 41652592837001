import React from 'react'

export default function Banner({ data }) {
    return (
        <>
            <div className="portfolio-hero">
                <div className="portfolio-hero__container"></div>
                <div className="portfolio-hero__image">
                    <img src={process.env.PUBLIC_URL + data?.image} />
                </div>

                <h3 className="portfolio-hero__subtitle">{data?.subtitle}</h3>
            </div>
        </>
    )
}
