import React from 'react'

export default function Blog() {
    return (
        <>
            <div className="cd-section amoeba" id="section6">
                <section className="blog">
                    <div className="blog__container">
                        <h2 className="blog__title">últimos Posts do nosso Blog</h2>
                        <div className="blog__header">
                            <div className="blog__card">
                                <div className="blog__card__info">
                                    <div className="blog__card__info-date">15 Mar 2021</div>
                                    <div className="blog__card__title">
                                        <a href="https://medium.com/@thailan/criando-imagem-docker-e-enviando-ao-dockerhub-45d3ce9306d4">
                                            Criando imagem Docker e enviando ao DockerHub
                                        </a>
                                    </div>
                                    <div className="blog__card__by">Por <b> Thailan Higor </b></div>
                                </div>
                            </div>
                            <div className="blog__card">
                                <div className="blog__card__info">
                                    <div className="blog__card__info-date">5 Mar 2021</div>
                                    <div className="blog__card__title">
                                        <a href="https://medium.com/@thailan/python-api-mais-r%C3%A1pida-do-oeste-f9bc401a2a9d">
                                            Desenvolvendo uma API com Python e Flask
                                        </a>
                                    </div>
                                    <div className="blog__card__by">Por <b> Thailan Higor </b></div>
                                </div>
                            </div>
                            <div className="blog__card">
                                <div className="blog__card__info">
                                    <div className="blog__card__info-date">22 Fev 2021</div>
                                    <div className="blog__card__title">
                                        <a href="https://medium.com/@thailan/orienta%C3%A7%C3%A3o-a-objetos-6cd2c857026a">
                                            Orientação a Objetos
                                        </a>
                                    </div>
                                    <div className="blog__card__by">Por <b> Thailan Higor </b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
