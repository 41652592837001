import React, { useEffect } from 'react'
import Header from '../../components/Home/Header'
import Banner from '../../components/Home/Banner'
import About from '../../components/Home/About'
import Packages from '../../components/Home/Packages'
import Portfolio from '../../components/Home/Portfolio'
import OurTeam from '../../components/Home/OurTeam'
import Blog from '../../components/Home/Blog'
import Form from '../../components/Home/Form'
import Footer from '../../components/Home/Footer'

import { cases } from '../data'

function Home() {

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "/js/amoeba.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div id="lazy-container">
            <Header />
            <Banner />
            <About />
            <Packages />
            <Portfolio cases={cases} />
            <OurTeam />
            <Blog />
            <Form />
            <Footer />

            <div class="content content--related"></div>

        </div>
    )

}

export default Home