import React from 'react'
export default function OtherCases({ data }) {
  console.log(data)
  return (
    <>
      <div className="portfolio-related">
        <div className="portfolio-related__container">
          <h2 className="portfolio-related__header">Outros Cases</h2>
          {
            data?.map((item, index) => (
              <a className="portfolio-related__card" href={`/cases/${item.slug}`} key={index}>
                <div className="portfolio-related__image">
                  <img src={process.env.PUBLIC_URL +  item.logo } />
                </div>
                <h3 className="portfolio-related__title">{item.name}</h3>
              </a>
            ))
          }


        </div>
      </div>
    </>
  )
}
