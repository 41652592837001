import React from 'react'

export default function Footer() {
    return (
        <footer>
            <div className="morph-wrap">
                <svg className="morph" width="100%" height="100%" viewBox="0 0 2500 1500">
                    <path d="M 584.713 421.99 C 605.1 462.566 594.69 546.729 542.489 569.242 C 490.287 591.756 442.771 574.048 411.516 531.686 C 380.261 489.325 321.31 447.519 252.125 477.178 C 182.94 506.837 76.019 510.636 15.588 390.223 C -44.843 269.811 82.177 127.516 234.846 171.293 C 387.516 215.07 396.581 81.649 449.77 36.319 C 502.957 -9.01 576.06 -17.091 647.262 44.325 C 718.462 105.741 738.609 217.477 637.237 286.676 C 535.865 355.876 564.327 381.412 584.714 421.989 Z" fill="#80e400" />
                </svg>
            </div>
            <div className="cd-section amoeba" id="section8">
                <section className="footer">
                    <div className="footer__header">
                        <div className="footer__header-title">NOSSA SEDE ESTÁ LOCALIZADA EM <br /> VOLTA REDONDA - RJ<br />  A CIDADE DO AÇO.</div>
                        <h3 className="footer__header-subtitle">
                            Contato - (24) 9 9926-6523<br />
                            Seg à Sex - 9:00 às 18:30<br />
                            <a href="mailto:contato@olivatech.dev"><b>contato@olivatech.dev</b></a><br />
                        </h3>
                    </div>
                    <div className="footer__copy">
                        <div className="footer__copy__brand">
                            <img src={process.env.PUBLIC_URL + "/assets/olivatech/logo-h-preto.png"} />
                        </div>
                        <div className="footer__copy__social-list">
                            <div className="footer__copy__social">
                                <a target="_blank" href="https://www.instagram.com/olivatech.dev/">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/instagram-icon.svg"} />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </footer>
    )
}
