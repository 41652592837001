import React from 'react'

export default function Header({ data }) {
    return (
        <>
            <div className="portfolio-header amoeba">
                <div className="portfolio-header__container">
                    <div className="portfolio-header__back">
                        <a href="/">
                            <img src={process.env.PUBLIC_URL + "/assets/icons/arrow-h-back.svg"} />
                        </a></div>
                    <div className="portfolio-header__logo">
                        <a href="/">
                            <img src={process.env.PUBLIC_URL + "/assets/olivatech/logo-h-preto.png"} />
                        </a>
                    </div>
                    <p className="portfolio-header__case">Case</p>
                    <div className="portfolio-header__title">
                        <h2 className="portfolio-header__company-name">{data?.name}</h2>
                        <div className="portfolio-header__tags">
                            {
                                data?.tags.map((item, index) => (
                                    <a key={index}>
                                        <p>{item}</p>
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                    <div className="portfolio-header__description">
                        <p dangerouslySetInnerHTML={{ __html: data?.description }} />
                        
                    </div>
                </div>
            </div>
        </>
    )
}
