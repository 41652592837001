import React from 'react'

export default function OurTeam() {
    return (
        <>
            <div className="cd-section amoeba" id="section5">
                <section className="team">
                    <div className="team__background"></div>
                    <div className="team__yellow-shape">
                        <img src={process.env.PUBLIC_URL + "/assets/olivatech/logo-o-branco.png"} />
                    </div>

                    <div className="team__container">
                        <div className="team__header">
                            <h1 className="team__title">Com os melhores parceiros do mercado.</h1>
                            <h2 className="team__subtitle">
                                <strong>Se quer ir rápido, vá sozinho. Se quer ir longe, vá em grupo. </strong>
                                A Oliva Tech está indo longe e não está sozinha nessa porque conta com os melhores parceiros nessa caminhada.
                            </h2>
                        </div>
                        <div className="team__list partner__list">
                            <div className="team__item partner__item">
                                <div className="team__image"><img src={process.env.PUBLIC_URL + "/assets/partners/google.png"} /></div>
                            </div>
                            {/* <div className="team__item partner__item">
                                <div className="team__image"><img src={process.env.PUBLIC_URL + "/assets/partners/am4.png"} /></div>
                            </div> */}
                            <div className="team__item partner__item">
                                <div className="team__image"><img src={process.env.PUBLIC_URL + "/assets/partners/aws.png"} /></div>
                            </div>
                            <div className="team__item partner__item">
                                <div className="team__image"><img src={process.env.PUBLIC_URL + "/assets/partners/microsoft.png"} /></div>
                            </div>
                            <div className="team__item partner__item">
                                <div className="team__image"><img src={process.env.PUBLIC_URL + "/assets/partners/atlassian.png"} /></div>
                            </div>
                          

                        </div>
                    </div>

                    <div className="team__container">
                        <div className="team__header">
                            <h1 className="team__title">Um time de especialistas.</h1>
                            <h2 className="team__subtitle">
                                Programação, design, conteúdo, gestão e planejamento.
                                A galera aqui tá pronta para trabalhar de perto com os clientes e criar aplicações incríveis e únicas.
                            </h2>
                        </div>
                    </div>


                </section>
            </div>
        </>
    )
}
