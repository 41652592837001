import React from 'react'

export default function Problem({ data }) {
    return (
        <>
            <div className="portfolio-problem">
                <div className="portfolio-problem__container">
                    <div className="portfolio-problem__header">
                        <h2 className="portfolio-problem__title">O Problema</h2>
                        <div className="portfolio-problem__asset"></div>
                        <div className="portfolio-problem__description">
                            <p dangerouslySetInnerHTML={{ __html: data?.description }} />

                        </div>
                        <div className="portfolio-problem__objectives">
                            <div className="portfolio-problem__objectives__title">
                                <h2>objetivos</h2>
                                <div className="portfolio-problem__points">
                                    {
                                        data?.objectives.map((item, index) => (
                                            <p key={index} >{index +1}. {item}</p>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-problem__responsive">
                        <div className="portfolio-problem__image">
                            <img src={process.env.PUBLIC_URL + data?.image_mobile} />

                        </div>
                        <div className="portfolio-problem__subtitle">Versão Responsiva</div>
                    </div>
                </div>
            </div>
        </>
    )
}
