import React from 'react'

export default function index({ data }) {
  return (
    <>
      <div className="portfolio-results portfolio-results--overlay amoeba">
        <div className="portfolio-results__background"></div>
        <div className="portfolio-results__container portfolio-results__container--overlay">
          <div className="portfolio-results__header">
            <h2 className="portfolio-results__title">Resultados</h2>
            <div className="portfolio-results__asset"></div>
            <p className="portfolio-results__description">
              {data?.description}
            </p>
            {
              data?.link &&
              <div className="portfolio-results__cta">
                <a target="_blank" href={data?.link} rel="noreferrer" >
                  <div className="btn-portfolio">Acesse o Site</div>
                </a>
              </div>
            }

          </div>
          <div className="portfolio-results__image portfolio-results__image--overlay">
            <img src={process.env.PUBLIC_URL + data?.image_tablet} alt="resultado" />
          </div>
        </div>
      </div>
    </>
  )
}
