import React from 'react'

export default function Packages() {
    return (
        <>
            <div className="cd-section amoeba" id="section3">
                <div className="packages">
                    <div className="packages__background"></div>
                    <div className="packages__pyramid">
                        <img src={process.env.PUBLIC_URL + "/assets/olivatech/logo-cortada.png"} />

                    </div>
                    <div className="packages__container">
                        <div className="packages__header">
                            <h2 className="packages__title">PACOTES ESPECIAIS PARA SUA EMPRESA OU NEGÓCIO</h2>
                            <p className="packages__subtitle">Pensamos nesse combo para sua <b>ideia sair do papel com tudo que ela tem direito.</b></p>
                        </div>
                        <div className="packages__card">
                            <div className="packages__card__icon-group"><b className="packages__card__title">PRESENÇA DIGITAL</b>
                                <img src={process.env.PUBLIC_URL + "/assets/icons/protoype-white.png"} />
                                <img src={process.env.PUBLIC_URL + "/assets/images/plataform-alt-icon.svg"} />
                                <img src={process.env.PUBLIC_URL + "/assets/images/interface-alt-icon.svg"} />
                            </div>
                            <div className="packages__card__rectangle">
                                <p className="packages__card__description">Identidade Visual <br/> Plataforma WEB <br/> Gestor Administrativo <br/> Aplicativo Móvel</p>
                            </div>
                        </div>
                        <div className="packages__card packages__card--bottom">
                            <div className="packages__card__icon-group"><b className="packages__card__title packages__card__title--bottom">CONVERSÃO E LEADS</b>
                                <img src={process.env.PUBLIC_URL + "/assets/icons/protoype-white.png"}  />
                                <img src={process.env.PUBLIC_URL + "/assets/images/interface-alt-icon.svg"} />

                            </div>
                            <div className="packages__card__rectangle packages__card__rectangle--bottom">
                                <p className="packages__card__description">Identidade Visual <br/> Landing Page <br/> Gestor Administrativo</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
