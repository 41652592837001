import React from 'react'
import InputMask from 'react-input-mask';

export default function InputMaskCustom(props) {
	return (
		<InputMask
			className="string tel required" 
			mask={props.mask}
			value={props.value}
			onChange={props.onChange}
			placeholderChar={'\u2000'}
			placeholder='(99) 99999-9999'
		/>
	)
}
