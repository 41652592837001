import React from 'react'

export default function About() {
    return (
        <>
            <div className="cd-section amoeba" id="section2">
                <section className="services">
                    <div className="services__header">
                        <h1 className="services__title">A SUA IDEIA, NÓS DESENVOLVEMOS.</h1>
                        <h2 className="services__subtitle">
                            Do planejamento à execução, cuidamos de cada detalhe para tornar seu projeto possível.
                            Tudo com muita experiência, agilidade e transparência, focando na qualidade e experiência do usuário.
                        </h2>
                    </div>
                    <div className="services__list">
                        <div className="services__list-item">
                            <h2 className="services__list-item__title"><span> 1º</span> PLANEJAMENTO</h2>
                            <div className="services__list-item__description">
                                <p>
                                    Começamos explorando e compreendendo a motivação por trás de tudo,
                                    e assim traçamos as melhores estratégias para chegar na incrível solução
                                    para você e seus usuários.
                                </p>
                            </div>
                            <div className="services__list-item__info">
                                <div className="services__list-item__details">
                                    <div className="services__list-item__tag">Design Sprint</div>
                                    <div className="services__list-item__tag">Testes com usuário</div>
                                    <div className="services__list-item__tag">Levantamento de requisitos</div>
                                </div>
                                <div className="services__list-item__image">
                                    <img src={process.env.PUBLIC_URL + "/assets/icons/planning.png"} />
                                </div>
                            </div>
                        </div>
                        <div className="services__list-item">
                            <h2 className="services__list-item__title"><span> 2º</span> PROTOTIPAÇÃO</h2>
                            <div className="services__list-item__description">
                                <p>
                                    A primeira impressão é a que fica.
                                    Antes de por a mão na massa de verdade, construimos e validamos vários protótipos
                                    a fim de garantir a melhor versão de projeto.
                                </p>
                            </div>
                            <div className="services__list-item__info">
                                <div className="services__list-item__details">
                                    <div className="services__list-item__tag">MVP</div>
                                    <div className="services__list-item__tag">Branding</div>
                                    <div className="services__list-item__tag">UI/UX design</div>
                                </div>
                                <div className="services__list-item__image">
                                    <img src={process.env.PUBLIC_URL + "/assets/icons/prototype.png"} />
                                </div>
                            </div>
                        </div>
                        <div className="services__list-item">
                            <h2 className="services__list-item__title"><span> 3º</span> PROGRAMAÇÃO</h2>
                            <div className="services__list-item__description">
                                <p>
                                    Aqui a mágica acontece! Seu ideia ganha vida e se torna funcional.
                                    Uma arquitetura bem planejado e código robusto é a base de qualquer plataforma.
                                </p>
                            </div>
                            <div className="services__list-item__info">
                                <div className="services__list-item__details">
                                    <div className="services__list-item__tag">Single pages applications</div>
                                    <div className="services__list-item__tag">Web development</div>
                                    <div className="services__list-item__tag">Aplicativos móveis</div>
                                </div>
                                <div className="services__list-item__image">
                                    <img src={process.env.PUBLIC_URL + "/assets/icons/programing.png"} />

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="services-products">
                    <div className="services-products__container">
                        <div className="services-products__title">
                            <h2>ESPECIALISTAS NO DESENVOLVIMENTO DE SOLUÇÕES DIGITAIS</h2>
                        </div>
                        <div className="services-products__list-item">

                            <div className="services-products__item js-service-toggle">
                                <div className="services-products__item__icon active">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/hotsite-icon.svg"} />

                                </div>
                                <div className="services-products__item__details  js-service-toggle-content active">
                                    <h2 className="services-products__item__title">Landing page</h2>
                                    <div className="services-products__item__description">
                                        <p>
                                            Uma página web voltada para conversões. ideal para exibir seus produtos, contar a história da sua empresa,
                                            mostrar seus diferenciais e ainda captar leads.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="services-products__item js-service-toggle services-products__item--right">
                                <div className="services-products__item__icon">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/ecommerce-icon.svg"} />

                                </div>
                                <div className="services-products__item__details services-products__item__details--3 js-service-toggle-content">
                                    <h2 className="services-products__item__title">Vendas Online</h2>
                                    <div className="services-products__item__description">
                                        <p>Uma plataforma totalmente personalizada para vendas online: segura, fácil de usar e desenvolvida com foco no seu cliente.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="services-products__item js-service-toggle">
                                <div className="services-products__item__icon">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/interface-icon.svg"} />

                                </div>
                                <div className="services-products__item__details services-products__item__details--4 js-service-toggle-content">
                                    <h2 className="services-products__item__title">Soluções WEB</h2>
                                    <div className="services-products__item__description">
                                        <p>
                                            Crie uma interface customizada de acordo com as necessidades da sua empresa ou ideia.
                                            Controle de estoque, cadastro de clientes, agendamento de serviços.
                                            Uma plataforma para automatizar seus processos em geral!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="services-products__item js-service-toggle services-products__item--right">
                                <div className="services-products__item__icon">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/mobile-ui-icon.svg"} />
                                </div>
                                <div className="services-products__item__details services-products__item__details--4 js-service-toggle-content">
                                    <h2 className="services-products__item__title">APP</h2>
                                    <div className="services-products__item__description">
                                        <p>
                                            Tudo hoje está na palma das mãos. E você não vai ficar fora dessa!
                                            Desenvolvimento simplificado e personalizado para fidelizar aindam mais seus usuários e clientes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="services-products__item js-service-toggle">
                                <div className="services-products__item__icon">
                                    <img src={process.env.PUBLIC_URL + "/assets/icons/customize.png"}  style={{width: "40px"}}/>

                                </div>
                                <div className="services-products__item__details js-service-toggle-content services-products__item__details--2">
                                    <h2 className="services-products__item__title">Não é isso que eu preciso</h2>
                                    <div className="services-products__item__description">
                                        <p>
                                            Então você precisa de algo totalmente novo?
                                            Entre em contato conosco e vamos conversar melhor sobre seu projeto!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="services-products__asset">
                            <img src={process.env.PUBLIC_URL + "/assets/images/services-yellow.png"} />

                        </div>
                    </div>
                    <div className="clearfix"></div>
                </section>
            </div>
        </>
    )
}
