import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';

import Header from '../../components/Case/Header'
import Footer from '../../components/Home/Footer'
import Banner from '../../components/Case/Banner'
import Problem from '../../components/Case/Problem'
import Solution from '../../components/Case/Solution'
import Result from '../../components/Case/Result'
import Form from '../../components/Case/Form'
import OtherCases from '../../components/Case/OtherCases'

import { cases } from '../data'

function Case() {
    const { slug } = useParams();
    const [data, setData] = useState({})

    useEffect(() => {
        setData(cases.filter((project) => project.slug === slug)[0])

        const script = document.createElement('script');

        script.src = "/js/amoeba_portfolio.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div id="lazy-container">
            <Header data={data.header} />
            <Banner data={data.banner} />
            <Problem data={data.problem} />
            <Solution data={data.solution} />
            <Result data={data.result} />
            <Form data={data} />
            <OtherCases data={data.otherCases} />
            <Footer data={data} />

            <div className="content content--related"></div>
        </div>
    )

}

export default Case