import React from 'react'

export default function Banner() {
    return (
        <>
            <div className="cd-section" id="section0"></div>
            <div className="cd-section amoeba" id="section1">
                <section className="hero">
                    <div className="hero__black-shape"></div>
                    <div className="container">
                        <div className="hero__brand">
                            <img src={process.env.PUBLIC_URL + "/assets/olivatech/logo-v-preto.png"} />

                        </div>
                        <div className="hero__body">
                            <h2 className="hero__subtitle">
                                Criando soluções tecnológicas e simplificadas com objetivo de proporcionar uma experiência digital incrível.
                            </h2>
                            <h1 className="hero__title">VOCÊ TEM UMA GRANDE IDEIA? COMECE AQUI.</h1>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
